import React from 'react';
import Home from '../../components/home/v3/Home';
import Layout from '../../layouts/index';

class HomePageClacDesDoigts extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { props } = this;
    return (
      <Layout routeSlug="HomePageClacDesDoigts">
        <Home {...props} showBannerClacDesDoigts />
      </Layout>
    );
  }
}

export default HomePageClacDesDoigts;
